import currency from 'currency.js'
import { DEFAULT_CURRENCY } from 'constants/currency'

export const getLocaleFormattedPrice = (price: number | string, withSymbol = true) =>
  `${Number(price).toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
    // minimumFractionDigits: 2,
  })} ${withSymbol ? DEFAULT_CURRENCY : ''}`

export const getTaxAmount = (amount: number) => currency(amount).multiply(0.2).value
