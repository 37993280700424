import { useCallback, useState } from 'react'
import { Box, Flex, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import {
  IconChevronLeft,
  IconChevronRight,
  IconFile,
  IconFileTypeCsv,
  IconFileTypeDocx,
  IconFileTypePdf,
  IconFileTypeXls,
  IconFileTypeZip,
} from '@tabler/icons-react'
import useEmblaCarousel from 'embla-carousel-react'
import { File, FileType } from 'generated/generated-graphql'
import Image from 'next/image'
import Link from 'next/link'
import { useAuth } from 'providers/UserProvider'
import { ImageGalleryModalv2 } from './common/ImageGalleryModalv2'

export const getFileIcon = ({ fileName }: { fileName: string }) => {
  const fileExtension = fileName.split('.').pop()

  switch (fileExtension) {
    case 'jpg':
    case 'png':
    case 'webp':
      return null
    case 'pdf':
      return <IconFileTypePdf stroke={1.5} color="#E07900" />
    case 'docx':
      return <IconFileTypeDocx stroke={1.5} color="#E07900" />
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'xls':
    case 'xlm':
      return <IconFileTypeXls stroke={1.5} color="#E07900" />
    case 'zip':
    case 'rar':
      return <IconFileTypeZip stroke={1.5} color="#E07900" />
    case 'csv':
      return <IconFileTypeCsv stroke={1.5} color="#E07900" />
    default:
      return <IconFile stroke={1.5} color="#E07900" />
  }
}

export const AttachmentList = ({ files }: { files?: File[] | null }) => {
  const { hasMissingOrExpiredSubscription } = useAuth()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const images = files?.filter(({ type }) => type === FileType.Image)
  const imagesWithSrc = images?.filter(({ fileUrl }) => fileUrl)
  const documents = files?.filter(({ type }) => type !== FileType.Image)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { slidesToScroll: 'auto', containScroll: 'trimSnaps', dragFree: true },
    [],
  )

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  return files && files.length !== 0 ? (
    <VStack w="full" align="start" spacing="16px">
      {documents?.length !== 0 && (
        <VStack align="start" spacing="4px">
          {documents?.map(({ filename, fileSizeInKib, downloadUrl }) =>
            !hasMissingOrExpiredSubscription && downloadUrl ? (
              <Link
                prefetch={false}
                key={filename}
                href={downloadUrl}
                onClick={(event) => {
                  event.preventDefault()
                  window.open(downloadUrl, '_blank', 'noopener,noreferrer')
                }}
              >
                <HStack spacing="32px">
                  <HStack spacing="8px">
                    <Box w="24px" h="24px">
                      {getFileIcon({ fileName: filename })}
                    </Box>
                    <Text
                      maxW="300px"
                      noOfLines={1}
                      fontFamily="DM Mono"
                      color="#E07900"
                      textDecor="underline"
                    >
                      {filename}
                    </Text>
                  </HStack>
                  <Text fontFamily="DM Mono" minW="fit-content" color="brand.darkBlue60">
                    _{fileSizeInKib} kB
                  </Text>
                </HStack>
              </Link>
            ) : (
              <HStack spacing="32px">
                <HStack spacing="8px">
                  <Box w="24px" h="24px">
                    {getFileIcon({ fileName: filename })}
                  </Box>
                  <Text
                    maxW="300px"
                    noOfLines={1}
                    fontFamily="DM Mono"
                    color="#E07900"
                    textDecor="underline"
                  >
                    {filename}
                  </Text>
                </HStack>
                <Text fontFamily="DM Mono" minW="fit-content" color="brand.darkBlue60">
                  _{fileSizeInKib} kB
                </Text>
              </HStack>
            ),
          )}
        </VStack>
      )}
      {images && imagesWithSrc?.length !== 0 && (
        <>
          <Flex w="full" gap="8px" align="center">
            <IconChevronLeft
              onClick={() => {
                scrollPrev()
              }}
            />
            <Box w="full" ref={emblaRef} overflow="hidden">
              <Flex w="full" justify="space-between" gap="16px">
                {imagesWithSrc?.map(
                  ({ fileUrl, filename }, index) =>
                    fileUrl && (
                      <Image
                        key={filename}
                        alt={filename}
                        src={fileUrl}
                        width={160}
                        height={120}
                        style={{
                          height: 'auto',
                          maxHeight: '120px',
                          objectFit: 'contain',
                        }}
                        onClick={() => {
                          setSelectedIndex(index)
                          onOpen()
                        }}
                      />
                    ),
                )}
              </Flex>
            </Box>
            <IconChevronRight
              onClick={() => {
                scrollNext()
              }}
            />
          </Flex>
        </>
      )}
      {imagesWithSrc && (
        <ImageGalleryModalv2
          title="Fotografie / Ilustrácie"
          images={imagesWithSrc}
          isOpen={isOpen}
          onClose={onClose}
          defaultIndex={selectedIndex}
        />
      )}
    </VStack>
  ) : (
    <Text>Bez príloh</Text>
  )
}
