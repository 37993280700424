export const GetBase64: (file?: File) => Promise<string> = (file) =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve('')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => typeof reader.result === 'string' && resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })

export const removeFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf('.')
  return lastDotIndex === -1 ? filename : filename.substring(0, lastDotIndex)
}

export const getFileExtension = (filename: string): string | null => {
  const lastDotIndex = filename.lastIndexOf('.')
  return lastDotIndex === -1 ? null : filename.substring(lastDotIndex + 1)
}
