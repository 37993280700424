import { useCallback, useEffect, useState } from 'react'
import { AspectRatio, Box, Divider, Flex, ModalProps, Text, VStack } from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import useEmblaCarousel from 'embla-carousel-react'
import { File } from 'generated/generated-graphql'
import Image from 'next/image'
import { Modal } from 'components/ui/Modal'
import { removeFileExtension } from 'utils/file'

interface ImageGalleryModalProps extends Omit<ModalProps, 'children'> {
  title: string
  isOpen: boolean
  onClose: () => void
  images: File[]
  defaultIndex?: number
}

export const ImageGalleryModalv2 = ({
  isOpen,
  onClose,
  images,
  title,
  defaultIndex,
}: ImageGalleryModalProps) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ startIndex: defaultIndex })
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  })

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) {
        return
      }
      emblaMainApi.scrollTo(index)
    },
    [emblaMainApi, emblaThumbsApi],
  )

  const scrollPrev = useCallback(() => emblaMainApi && emblaMainApi.scrollPrev(), [emblaMainApi])
  const scrollNext = useCallback(() => emblaMainApi && emblaMainApi.scrollNext(), [emblaMainApi])
  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) {
      return
    }
    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaMainApi) {
      return
    }
    onSelect()

    emblaMainApi.on('select', onSelect).on('reInit', onSelect)
  }, [emblaMainApi, onSelect])

  return (
    <Modal
      width="fit-content"
      preserveScrollBarGap
      hasCloseIcon
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <VStack
        align="start"
        maxW="1000px"
        spacing="24px"
        divider={<Divider w="full" borderColor="#CCCCCC" />}
      >
        <VStack w="full" overflowY="scroll" spacing="48px" overflow="hidden">
          <Flex w="full" gap="48px" align="center">
            <IconChevronLeft
              onClick={() => {
                scrollPrev()
              }}
            />
            <Box w="800px" ref={emblaMainRef} overflow="hidden">
              <Flex>
                {images.map(
                  ({ fileUrl, filename }) =>
                    fileUrl && (
                      <VStack key={filename} w="800px" align="center">
                        <Box w="800px">
                          <AspectRatio maxW="1000px" ratio={1000 / 600}>
                            <Box position="relative">
                              <Image
                                alt={filename}
                                src={fileUrl}
                                fill
                                style={{
                                  objectFit: 'contain',
                                }}
                              />
                            </Box>
                          </AspectRatio>
                        </Box>
                        <Text fontFamily="DM Mono">{removeFileExtension(filename)}</Text>
                      </VStack>
                    ),
                )}
              </Flex>
            </Box>

            <IconChevronRight
              onClick={() => {
                scrollNext()
              }}
            />
          </Flex>
          <Box w="full" ref={emblaThumbsRef} overflow="hidden">
            <Flex w="full" justify="center" gap="24px">
              {images.map(
                ({ fileUrl, filename }, index) =>
                  fileUrl && (
                    <Box
                      key={filename}
                      w="140px"
                      borderRadius="12px"
                      border={selectedIndex === index ? '2px solid #007FFF' : '1px solid #CCCCCC'}
                      overflow="hidden"
                      boxShadow="md"
                      onClick={() => onThumbClick(index)}
                    >
                      <AspectRatio maxW="140px" ratio={1000 / 600}>
                        <Box position="relative">
                          <Image
                            alt={filename}
                            src={fileUrl}
                            fill
                            style={{
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      </AspectRatio>
                    </Box>
                  ),
              )}
            </Flex>
          </Box>
        </VStack>
      </VStack>
    </Modal>
  )
}
